export const patch = "15.7";

export const defaults = {
  lane: "default",
};

export const ids = {
  aatrox: 266,
  ahri: 103,
  akali: 84,
  akshan: 166,
  alistar: 12,
  ambessa: 799,
  amumu: 32,
  anivia: 34,
  annie: 1,
  aphelios: 523,
  ashe: 22,
  aurelionsol: 136,
  aurora: 893,
  azir: 268,
  bard: 432,
  belveth: 200,
  blitzcrank: 53,
  brand: 63,
  braum: 201,
  briar: 233,
  caitlyn: 51,
  camille: 164,
  cassiopeia: 69,
  chogath: 31,
  corki: 42,
  darius: 122,
  diana: 131,
  draven: 119,
  drmundo: 36,
  ekko: 245,
  elise: 60,
  evelynn: 28,
  ezreal: 81,
  fiddlesticks: 9,
  fiora: 114,
  fizz: 105,
  galio: 3,
  gangplank: 41,
  garen: 86,
  gnar: 150,
  gragas: 79,
  graves: 104,
  gwen: 887,
  hecarim: 120,
  heimerdinger: 74,
  hwei: 910,
  illaoi: 420,
  irelia: 39,
  ivern: 427,
  janna: 40,
  jarvaniv: 59,
  jax: 24,
  jayce: 126,
  jhin: 202,
  jinx: 222,
  kaisa: 145,
  kalista: 429,
  karma: 43,
  karthus: 30,
  kassadin: 38,
  katarina: 55,
  kayle: 10,
  kayn: 141,
  kennen: 85,
  khazix: 121,
  kindred: 203,
  kled: 240,
  kogmaw: 96,
  ksante: 897,
  leblanc: 7,
  leesin: 64,
  leona: 89,
  lillia: 876,
  lissandra: 127,
  lucian: 236,
  lulu: 117,
  lux: 99,
  malphite: 54,
  malzahar: 90,
  maokai: 57,
  masteryi: 11,
  mel: 800,
  milio: 902,
  missfortune: 21,
  mordekaiser: 82,
  morgana: 25,
  naafiri: 950,
  nami: 267,
  nasus: 75,
  nautilus: 111,
  neeko: 518,
  nidalee: 76,
  nilah: 895,
  nocturne: 56,
  nunu: 20,
  olaf: 2,
  orianna: 61,
  ornn: 516,
  pantheon: 80,
  poppy: 78,
  pyke: 555,
  qiyana: 246,
  quinn: 133,
  rakan: 497,
  rammus: 33,
  reksai: 421,
  rell: 526,
  renata: 888,
  renekton: 58,
  rengar: 107,
  riven: 92,
  rumble: 68,
  ryze: 13,
  samira: 360,
  sejuani: 113,
  senna: 235,
  seraphine: 147,
  sett: 875,
  shaco: 35,
  shen: 98,
  shyvana: 102,
  singed: 27,
  sion: 14,
  sivir: 15,
  skarner: 72,
  smolder: 901,
  sona: 37,
  soraka: 16,
  swain: 50,
  sylas: 517,
  syndra: 134,
  tahmkench: 223,
  taliyah: 163,
  talon: 91,
  taric: 44,
  teemo: 17,
  thresh: 412,
  tristana: 18,
  trundle: 48,
  tryndamere: 23,
  twistedfate: 4,
  twitch: 29,
  udyr: 77,
  urgot: 6,
  varus: 110,
  vayne: 67,
  veigar: 45,
  velkoz: 161,
  vex: 711,
  vi: 254,
  viego: 234,
  viktor: 112,
  vladimir: 8,
  volibear: 106,
  warwick: 19,
  wukong: 62,
  xayah: 498,
  xerath: 101,
  xinzhao: 5,
  yasuo: 157,
  yone: 777,
  yorick: 83,
  yuumi: 350,
  zac: 154,
  zed: 238,
  zeri: 221,
  ziggs: 115,
  zilean: 26,
  zoe: 142,
  zyra: 143,
};

export const champId = (path) => ids[path] || 0;

export const paths = {
  266: "aatrox",
  103: "ahri",
  84: "akali",
  166: "akshan",
  12: "alistar",
  799: "ambessa",
  32: "amumu",
  34: "anivia",
  1: "annie",
  523: "aphelios",
  22: "ashe",
  136: "aurelionsol",
  893: "aurora",
  268: "azir",
  432: "bard",
  200: "belveth",
  53: "blitzcrank",
  63: "brand",
  201: "braum",
  233: "briar",
  51: "caitlyn",
  164: "camille",
  69: "cassiopeia",
  31: "chogath",
  42: "corki",
  122: "darius",
  131: "diana",
  119: "draven",
  36: "drmundo",
  245: "ekko",
  60: "elise",
  28: "evelynn",
  81: "ezreal",
  9: "fiddlesticks",
  114: "fiora",
  105: "fizz",
  3: "galio",
  41: "gangplank",
  86: "garen",
  150: "gnar",
  79: "gragas",
  104: "graves",
  887: "gwen",
  120: "hecarim",
  74: "heimerdinger",
  910: "hwei",
  420: "illaoi",
  39: "irelia",
  427: "ivern",
  40: "janna",
  59: "jarvaniv",
  24: "jax",
  126: "jayce",
  202: "jhin",
  222: "jinx",
  145: "kaisa",
  429: "kalista",
  43: "karma",
  30: "karthus",
  38: "kassadin",
  55: "katarina",
  10: "kayle",
  141: "kayn",
  85: "kennen",
  121: "khazix",
  203: "kindred",
  240: "kled",
  96: "kogmaw",
  897: "ksante",
  7: "leblanc",
  64: "leesin",
  89: "leona",
  876: "lillia",
  127: "lissandra",
  236: "lucian",
  117: "lulu",
  99: "lux",
  54: "malphite",
  90: "malzahar",
  57: "maokai",
  11: "masteryi",
  800: "mel",
  21: "missfortune",
  902: "milio",
  82: "mordekaiser",
  25: "morgana",
  950: "naafiri",
  267: "nami",
  75: "nasus",
  111: "nautilus",
  518: "neeko",
  76: "nidalee",
  895: "nilah",
  56: "nocturne",
  20: "nunu",
  2: "olaf",
  61: "orianna",
  516: "ornn",
  80: "pantheon",
  78: "poppy",
  555: "pyke",
  246: "qiyana",
  133: "quinn",
  497: "rakan",
  33: "rammus",
  421: "reksai",
  526: "rell",
  888: "renata",
  58: "renekton",
  107: "rengar",
  92: "riven",
  68: "rumble",
  13: "ryze",
  360: "samira",
  113: "sejuani",
  235: "senna",
  147: "seraphine",
  875: "sett",
  35: "shaco",
  98: "shen",
  102: "shyvana",
  27: "singed",
  14: "sion",
  15: "sivir",
  72: "skarner",
  901: "smolder",
  37: "sona",
  16: "soraka",
  50: "swain",
  517: "sylas",
  134: "syndra",
  223: "tahmkench",
  163: "taliyah",
  91: "talon",
  44: "taric",
  17: "teemo",
  412: "thresh",
  18: "tristana",
  48: "trundle",
  23: "tryndamere",
  4: "twistedfate",
  29: "twitch",
  77: "udyr",
  6: "urgot",
  110: "varus",
  67: "vayne",
  45: "veigar",
  161: "velkoz",
  711: "vex",
  254: "vi",
  234: "viego",
  112: "viktor",
  8: "vladimir",
  106: "volibear",
  19: "warwick",
  62: "wukong",
  498: "xayah",
  101: "xerath",
  5: "xinzhao",
  157: "yasuo",
  777: "yone",
  83: "yorick",
  350: "yuumi",
  154: "zac",
  238: "zed",
  221: "zeri",
  115: "ziggs",
  26: "zilean",
  142: "zoe",
  143: "zyra",
};

export const champPath = (cid) => paths[cid] || "";

export const names = {
  266: "Aatrox",
  103: "Ahri",
  84: "Akali",
  166: "Akshan",
  12: "Alistar",
  799: "Ambessa",
  32: "Amumu",
  34: "Anivia",
  1: "Annie",
  523: "Aphelios",
  22: "Ashe",
  136: "Aurelion Sol",
  893: "Aurora",
  268: "Azir",
  432: "Bard",
  200: "Bel'Veth",
  53: "Blitzcrank",
  63: "Brand",
  201: "Braum",
  233: "Briar",
  51: "Caitlyn",
  164: "Camille",
  69: "Cassiopeia",
  31: "Cho'Gath",
  42: "Corki",
  122: "Darius",
  131: "Diana",
  119: "Draven",
  36: "Dr. Mundo",
  245: "Ekko",
  60: "Elise",
  28: "Evelynn",
  81: "Ezreal",
  9: "Fiddlesticks",
  114: "Fiora",
  105: "Fizz",
  3: "Galio",
  41: "Gangplank",
  86: "Garen",
  150: "Gnar",
  79: "Gragas",
  104: "Graves",
  887: "Gwen",
  120: "Hecarim",
  74: "Heimerdinger",
  910: "Hwei",
  420: "Illaoi",
  39: "Irelia",
  427: "Ivern",
  40: "Janna",
  59: "Jarvan IV",
  24: "Jax",
  126: "Jayce",
  202: "Jhin",
  222: "Jinx",
  145: "Kai'Sa",
  429: "Kalista",
  43: "Karma",
  30: "Karthus",
  38: "Kassadin",
  55: "Katarina",
  10: "Kayle",
  141: "Kayn",
  85: "Kennen",
  121: "Kha'Zix",
  203: "Kindred",
  240: "Kled",
  96: "Kog'Maw",
  897: "K'Sante",
  7: "LeBlanc",
  64: "Lee Sin",
  89: "Leona",
  876: "Lillia",
  127: "Lissandra",
  236: "Lucian",
  117: "Lulu",
  99: "Lux",
  54: "Malphite",
  90: "Malzahar",
  57: "Maokai",
  11: "Master Yi",
  800: "Mel",
  21: "Miss Fortune",
  902: "Milio",
  82: "Mordekaiser",
  25: "Morgana",
  950: "Naafiri",
  267: "Nami",
  75: "Nasus",
  111: "Nautilus",
  518: "Neeko",
  76: "Nidalee",
  895: "Nilah",
  56: "Nocturne",
  20: "Nunu",
  2: "Olaf",
  61: "Orianna",
  516: "Ornn",
  80: "Pantheon",
  78: "Poppy",
  555: "Pyke",
  246: "Qiyana",
  133: "Quinn",
  497: "Rakan",
  33: "Rammus",
  421: "Rek'Sai",
  526: "Rell",
  888: "Renata Glasc",
  58: "Renekton",
  107: "Rengar",
  92: "Riven",
  68: "Rumble",
  13: "Ryze",
  360: "Samira",
  113: "Sejuani",
  235: "Senna",
  147: "Seraphine",
  875: "Sett",
  35: "Shaco",
  98: "Shen",
  102: "Shyvana",
  27: "Singed",
  14: "Sion",
  15: "Sivir",
  72: "Skarner",
  901: "Smolder",
  37: "Sona",
  16: "Soraka",
  50: "Swain",
  517: "Sylas",
  134: "Syndra",
  223: "Tahm Kench",
  163: "Taliyah",
  91: "Talon",
  44: "Taric",
  17: "Teemo",
  412: "Thresh",
  18: "Tristana",
  48: "Trundle",
  23: "Tryndamere",
  4: "Twisted Fate",
  29: "Twitch",
  77: "Udyr",
  6: "Urgot",
  110: "Varus",
  67: "Vayne",
  45: "Veigar",
  161: "Vel'Koz",
  711: "Vex",
  254: "Vi",
  234: "Viego",
  112: "Viktor",
  8: "Vladimir",
  106: "Volibear",
  19: "Warwick",
  62: "Wukong",
  498: "Xayah",
  101: "Xerath",
  5: "Xin Zhao",
  157: "Yasuo",
  777: "Yone",
  83: "Yorick",
  350: "Yuumi",
  154: "Zac",
  238: "Zed",
  221: "Zeri",
  115: "Ziggs",
  26: "Zilean",
  142: "Zoe",
  143: "Zyra",
};

export const champName = (cid) => names[cid] || "";
